<template>
  <v-row class="px-4">
    <v-col
      class="text-center text-h4"
      cols="12"
    >
      Авторизация
    </v-col>

    <v-col class="d-flex justify-center">
      <v-card
        color="transparent"
        flat
        width="400"
      >
        <v-row>
          <v-col
            class="pb-2"
            cols="12"
          >
            <v-form>
              <v-text-field
                v-model="username"
                class="rounded-lg mb-5"
                hide-details="auto"
                label="Логин"
                outlined
              />
              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                class="rounded-lg mb-5"
                hide-details="auto"
                label="Пароль"
                outlined
                @click:append="show = !show"
              />
            </v-form>
          </v-col>

          <v-col>
            <ApolloMutation
              :mutation="require('../graphql/mutations/adminLogin.gql')"
              :variables="{
                username,
                password,
              }"
              @done="onLogin"
            >
              <template v-slot="{ mutate, loading, error }">
                <v-btn
                  :disabled="!username || !password"
                  :loading="loading"
                  block
                  class="text-none rounded-lg"
                  color="primary"
                  depressed
                  x-large
                  @click="mutate()"
                >
                  Войти
                </v-btn>
                <AppNotification
                  v-if="error"
                  :icon-disabled="true"
                  :message="error.message"
                />
              </template>
            </ApolloMutation>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AppNotification from '@/components/AppNotification.vue';

export default {
  components: {
    AppNotification,
  },
  data() {
    return {
      username: '',
      password: '',
      show: false,
    };
  },
  methods: {
    onLogin({ data }) {
      localStorage.setItem('token', data.adminLogin.accessToken);
      this.$router.push('/');
    },
  },
};
</script>
